import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  getLinkToValidateLicense,
  closeResponseGetLinkToValidateLicense,
  closeResponseValidateLicense,
  validateLicense,
} from 'store/customers/actions';
import DriverLicense from './DriverLicense';

export default compose(
  connect(
    (state) => ({
      statesList: _get(state, 'commons.states.list', []),
      nacionalities: _get(state, 'commons.nationalities.list', []),
      data: _get(state, 'customers.customerDetail.data.driverLicense', {}),
      driverLicenseValidations: _get(
        state,
        'customers.customerDetail.data.driverLicenseValidations',
        {}
      ),
      innCodeScore: _get(
        state,
        'customers.identityValidation.data.innCodeInfo.score',
        {}
      ),
      innCodeImages: _get(
        state,
        'customers.identityValidation.data.innCodeInfo.secondIdImages',
        {}
      ),
      licenseInnCodeData: _get(
        state,
        'customers.licenseValidation.data.innCodeInfo.data.ocrData.license',
        {}
      ),
      licenseValidation: _get(state, 'customers.licenseValidation', {}),
      urlInnCodeInfo: _get(
        state,
        'customers.licenseValidation.data.urlInfo',
        {}
      ),
      innCodeInfo: _get(
        state,
        'customers.licenseValidation.data.innCodeInfo',
        {}
      ),
      flowInnCodeCompleted: _get(
        state,
        'customers.licenseValidation.data.innCodeInfo.flowCompleted',
        true
      ),
    }),
    {
      getLinkToValidateLicense,
      closeResponseGetLinkToValidateLicense,
      closeResponseValidateLicense,
      validateLicense,
    }
  )
)(DriverLicense);
