import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useEffect, useCallback, useState } from 'react';
import {
  TableNavigation,
  Button,
  Table,
  withForm,
  Dropdown,
} from '@casanova/casanova-common';

import { showLoader, hideLoader } from 'store/commons/actions';
import i18n from '@i18n';
import RoleVerifier from 'components/RoleVerifier';
import { transformDropdownElements } from 'utils/transformHelpers';
import { isIST } from '@casanova/casanova-common/lib/utils/env';
import {
  CREATE_VEHICLE_BATCH,
  CREATE_VEHICLE_MANUAL,
  VIEW_VEHICLE_EXCEL,
  VIEW_VEHICLE_CATALOG_RD,
} from 'utils/roles/permissions';
import ViewVehicleDetail from '@vehicles/ViewVehicleDetail';
import useCleanModule from 'hooks/useCleanModule';
import {
  MASSIVE_LOAD_TYPES,
  VEHICLE_STATUS_UUIDS,
  BUSINESS_AREA_UUIDS,
} from '@vehicles/common/constants';
import { fetchAllVehicles } from 'services/vehicles';
import { validateRolePermissions } from 'utils/roles';
import columns, {
  contextualMenu,
  getContextualMenu,
  getVehicleContextualMenu,
  MASSIVE_LOAD_OPTIONS,
} from './columns';
import { config } from './config';
import {
  fetchAllVehiclesPrepared,
  prepareAllVehicles,
} from '../../../services/vehicles';

// Flow temporarily disabled
// const MarkForSale = lazy(() => import('@vehicles/MarkForSale'));

function VehicleList({
  vehicles,
  fetchVehicles,
  fetchVehiclesFilters,
  setVehiclesPage,
  setMassiveLoadType,
  history,
  match,
  onUpdateForm,
  fetchCatalog,
  exportVehiclesCatalog,
  exportCatalogRecords,
}) {
  const [showVehicleDetail, setShowVehicleDetail] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState('');

  useEffect(() => {
    fetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/business-areas',
    });
    fetchVehiclesFilters();
  }, [fetchCatalog, fetchVehiclesFilters]);

  // Flow temporarily disabled
  // const [showMarkForSale, setShowMarkForSale] = useState(false);

  const { params, searchPattern, filters } = vehicles;

  const setStaticParams = (fields) => {
    Object.entries(fields).forEach(([key, value]) => {
      params[key] = value;
    });
  };

  useCleanModule({ module: 'vehicles' });

  useEffect(() => {
    /* Si son usuarios de renta diaria, tendrán filtros estaticos */
    if (validateRolePermissions(VIEW_VEHICLE_CATALOG_RD)) {
      setStaticParams({
        businessArea: BUSINESS_AREA_UUIDS.RENTA_DIARIA,
        status: VEHICLE_STATUS_UUIDS.DISPONIBLE,
      });
    }

    fetchVehicles({ params, filters, searchPattern });
  }, [fetchVehicles, params, filters, searchPattern]);

  const handleAddClick = useCallback(() => {
    history.push('/vehicle/add');
  }, [history]);

  const handleDropdownSelect = useCallback(
    (section) => {
      const { name = '', uuid = '' } = section;
      setSelectedVehicle(uuid);

      switch (name) {
        case contextualMenu.VEHICLE_INFO.name:
          history.push(`/vehicle/${uuid}/detail`);
          break;
        case contextualMenu.VEHICLE_DETAILS.name:
          setShowVehicleDetail(true);
          break;
        // Flow temporarily disabled
        // case contextualMenu.MARK_FOR_SALE.name:
        //   setShowMarkForSale(true);
        //   break;
        case contextualMenu.VEHICLE_HISTORIC_DETAIL.name:
          history.push(`/vehicle/${uuid}/historic-detail`);
          break;
        case contextualMenu.REGISTER_OF_SINISTER.name:
          history.push(`/vehicle/${uuid}/detail?register_sinister=true`);
          break;
        default:
          break;
      }
    },
    [history]
  );

  const handleSelectMassiveLoad = ({ name }) => {
    switch (name) {
      case MASSIVE_LOAD_OPTIONS.VEHICLE.name:
        setMassiveLoadType(MASSIVE_LOAD_TYPES.VEHICLES);
        history.push('/vehicle/massive-load');
        break;
      case MASSIVE_LOAD_OPTIONS.VEHICLES_FOR_SALES.name:
        setMassiveLoadType(MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES);
        history.push('/vehicle/massive-load-for-sales');
        break;
      default:
        break;
    }
  };

  const changeTitles = (payload) =>
    payload.map((el) => ({
      Marca: el.brand,
      Línea: el.line,
      Versión: el.version,
      Modelo: el.model,
      Serie: el.serialNumber,
      Placas: el.plateNumber,
      Proveedor: el.provider, // In service
      Propietario: el.owner, // In service
      Precio: el.price || 'N/A', // In service
      Fecha: el.departureDate || 'N/A', // In service
      Compra: el.invoiceNumber || 'N/A', // In service
      Kilometraje: el.mileage || 'N/A', // In service
      Motor: el.engineNumber || 'N/A', // In service
      'Clave vehicular': el.vehicularKey || 'N/A', // In service
      Origen: el.origin || 'N/A', // In service
      'Número de cilindros': el.cylinder || 'N/A', // In service
      'Tipo de combustible': el.fuelType || 'N/A', // In service
      Color: el.color || 'N/A', // In service
      Transmisión: el.transmission || 'N/A', // In service
      'Tipo de asientos': el.seat || 'N/A', // In service
      'Número de puertas': el.doors || '0', // In service
      'Capacidad de carga (ton)': el.capacidad_carga || 'N/A',
      '¿El vehículo tiene adaptaciones?': el.activeAdaptation || 'NO', // In service
      'Origen de la adaptación': el.adaptationOrigin || 'N/A', // In service
      'Tipo de adaptación': el.adaptationType || 'N/A', // In service
      'Costo total adaptación': el.adaptationPrice || 'N/A', // In service
      'Número de factura': el.invoiceNumber || '0', // In service
      'Fecha de compra': el.purchaseDate || 'N/A', // In service
      'Precio de compra': el.purchasePrice || '0', // In service
      'Descripción de compra': el.purchaseBuy || 'N/A', // In service
      'Uso personal': el.uso_personal || 'N/A',
      'Solicitante de uso personal': el.solicitante_personal || 'N/A',
      'Observaciones de uso personal': el.uso_personal_observaciones || 'N/A',
      'Unidad de negocio': el.businessArea || 'N/A', // In service
      'Nombre del cliente': el.naturalPerson || 'N/A', // In service
      'Id / Folio de contrato': el.folio || 'N/A', // In service (casi)
      'Contrato licitación': el.tenderContract || 'No', // In service
      'Sucursal de arrendamiento': el.pickUp || 'N/A', // In service
      GPS: el.gps || 'N/A',
      Almacén: el.warehouse || 'N/A',
      'Clasificación Patrimonial': el.categories || 'N/A',
      'Categorización comercial-Nombre': el.unidad_negocio || 'N/A',
      'Categorización comercial-ACRIS': el.unidad_negocio || 'N/A',
      Conversión: el.unidad_negocio || 'N/A',
      'Fecha de venta': el.unidad_negocio || 'N/A',
      'Fecha de facturación': el.unidad_negocio || 'N/A',
      Cliente: el.unidad_negocio || 'N/A',
      'Monto de venta': el.unidad_negocio || 'N/A',
      'Folio de venta': el.unidad_negocio || 'N/A',
      Descripción: el.unidad_negocio || 'N/A',
      'Fecha de cancelación': el.unidad_negocio || 'N/A',
      Estatus: el.status, // In service
      Substatus: el.subStatus, // In service
    }));

  const pageableInformation = (currentParams, page, size) => ({
    params: {
      ...currentParams,
      page,
      size,
      property: 'id',
      direction: 'asc',
    },
    filters,
    searchPattern,
  });

  const getInformation = async (params) =>
    (await fetchAllVehicles(pageableInformation(params, 0, 50000), {})).data
      .content;

  const [ExcelBottom, setExcelBottom] = useState('Descargar Excel');
  const [ExcelBottomDisabled, setExcelBottomDisabled] = useState(false);

  const fetchByQueryId = async (queryId) => {
    const intervalId = setInterval(async () => {
      try {
        const results = await fetchAllVehiclesPrepared(queryId);

        if (results?.errorCode) {
          // File not ready, continue polling
          return;
        }
        if (results?.data?.content) {
          // File is ready, download it
          setExcelBottom('Descargando información...');
          const worksheet = XLSX.utils.json_to_sheet(
            changeTitles(results?.data?.content)
          );
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            'Lista de vehiculos'
          );
          XLSX.writeFile(workbook, 'Vehiculos.xlsx', { compression: true });
          setExcelBottom('Descargar Excel');
          setExcelBottomDisabled(false);

          localStorage.removeItem('queryId');

          clearInterval(intervalId); // Stop polling
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred during the download.');
        clearInterval(intervalId); // Stop polling on error
        setExcelBottom('Descargar Excel');
        setExcelBottomDisabled(false);
      }
    }, 5000);
  };

  const exportExcelFilter = async () => {
    setExcelBottom('Generando reporte...');
    setExcelBottomDisabled(true);
    const lastQuery = localStorage.getItem('queryId');
    if (lastQuery) {
      if (new Date() - new Date(lastQuery.date) < 300000) {
        fetchByQueryId(lastQuery.queryId);
        return;
      }
      localStorage.removeItem('queryId');
    }
    const result = await prepareAllVehicles(
      pageableInformation(vehicles.params, 0, 500000),
      {}
    );
    if (result) {
      localStorage.setItem('queryId', {
        queryId: result.data,
        date: new Date(),
      });

      fetchByQueryId(result.data);
    }
  };

  const exportExcelInforation = async (params) => {
    setExcelBottom('Generando reporte...');
    setExcelBottomDisabled(true);
    const lastQuery = localStorage.getItem('queryId');
    if (lastQuery) {
      if (new Date() - new Date(lastQuery.date) < 300000) {
        fetchByQueryId(lastQuery.queryId);
        return;
      }
      localStorage.removeItem('queryId');
    }
    const result = await prepareAllVehicles(
      pageableInformation(params, 0, 500000),
      {}
    );

    if (result) {
      localStorage.setItem('queryId', {
        queryId: result.data,
        date: new Date(),
      });

      fetchByQueryId(result.data);
    }
  };

  useEffect(() => {
    onUpdateForm(vehicles.params);
  }, [onUpdateForm, vehicles.params]);

  return (
    <>
      <ViewVehicleDetail
        show={showVehicleDetail}
        setShow={setShowVehicleDetail}
        match={match}
        vehicleUuid={selectedVehicle}
      />
      <div className="row">
        <div className="col d-flex align-items-center justify-content-between" />
        <RoleVerifier identifier={VIEW_VEHICLE_EXCEL}>
          <div className="col mb-3 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => exportExcelInforation({ activeFloat: 'active' })}
              download
              outline
              block
              className="mr-1 w-50 mt-0"
              disabled={ExcelBottomDisabled}
            >
              Descargar Flota Activa
            </Button>
            <Button
              onClick={() => exportExcelInforation({ activeFloat: 'inactive' })}
              download
              outline
              block
              className="mr-1 w-50 mt-0"
              disabled={ExcelBottomDisabled}
            >
              Descargar Flota Inactiva
            </Button>
            <Button
              onClick={exportExcelFilter}
              download
              outline
              block
              className="mr-1 w-50 mt-0"
              disabled={ExcelBottomDisabled}
            >
              {ExcelBottom}
            </Button>
          </div>
        </RoleVerifier>
      </div>

      <Table
        additionalHeader
        rowClassName="row-clickable"
        dataList={vehicles.results.list}
        columns={columns.map((col) => ({
          ...col,
          renderer: (props = { data: {} }) => {
            const { status } = props.data;
            const Component = col.renderer;
            return (
              <Component
                {...props}
                dropdownOptions={transformDropdownElements({
                  elements: getVehicleContextualMenu(status, props.data),
                })}
              />
            );
          },
        }))}
        dropdownCell
        dropdownOptions={transformDropdownElements({
          elements: getVehicleContextualMenu(),
        })}
        onDropdownSelect={handleDropdownSelect}
      />
      <div className="row">
        <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
          <TableNavigation search={vehicles} onChange={setVehiclesPage} />
        </div>
        <div className="col-10 col-xl-5">
          <div className="row">
            <RoleVerifier identifier={CREATE_VEHICLE_BATCH}>
              <div className="offset-2 offset-xl-0 col-4 col-xl-4">
                <Button
                  outline
                  block
                  className="mr-1"
                  onClick={() => history.push('/vehicle/massive-change')}
                >
                  Cambio masivo
                </Button>
              </div>
              <div className="offset-2 offset-xl-0 col-4 col-xl-4">
                {isIST() ? (
                  <Dropdown
                    menuItems={Object.values(MASSIVE_LOAD_OPTIONS)}
                    onSelect={handleSelectMassiveLoad}
                    withStyles={false}
                  >
                    {(props) => (
                      <Button outline block className="mr-1" {...props}>
                        Carga masiva
                      </Button>
                    )}
                  </Dropdown>
                ) : (
                  <Button
                    outline
                    block
                    className="mr-1"
                    onClick={() => history.push('/vehicle/massive-load')}
                  >
                    Carga masiva
                  </Button>
                )}
              </div>
            </RoleVerifier>
            <div className="col-4 col-xl-4">
              <RoleVerifier identifier={CREATE_VEHICLE_MANUAL}>
                <Button block onClick={handleAddClick}>
                  {i18n('COMMONS__NEW')}
                </Button>
              </RoleVerifier>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withForm({ config })(VehicleList);
