import _get from 'lodash/get';
import moment from 'moment';
import {
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/constants/index.js';

import { DRIVER_LICENSES_TYPES } from 'utils/licenses';
import { PERMANENT_VALIDITY } from './constants';

export const formatDriverLicense = (values, isForeign = false) => {
  const { isPermanentLicense = false, validityDriverLicense } = values;

  const validityYear = isPermanentLicense
    ? PERMANENT_VALIDITY
    : validityDriverLicense;

  const formattedDriverLicenseValues = {
    issuedYear: _get(values, 'expedition', ''),
    issuingStateUuid: _get(values, 'emissionState', ''),
    licenseTypeUuid: values.licenseType,
    nationalityUuid: values.nationality,
    number: values.licenseNumber,
    sinceDate: moment(values.antiquity).tz(timeZone).format(YYYY_MM_DD),
    validityYear,
  };

  if (isForeign) {
    // delete formattedDriverLicenseValues.issuedYear;
    delete formattedDriverLicenseValues.issuingStateUuid;
  }

  return formattedDriverLicenseValues;
};

export const customerLicenseInnCodeInfoFormatter = (values) => {
  const expireAt = _get(values, 'license.expireAt', 0);
  const isPermanentLicense = expireAt === 0;

  return {
    licenseType: DRIVER_LICENSES_TYPES[_get(values, 'license.classes', '')],
    validityDriverLicense: isPermanentLicense
      ? PERMANENT_VALIDITY
      : moment.utc(expireAt).format(YYYY_MM_DD),
    expedition: moment
      .utc(_get(values, 'license.issuedAt', 0))
      .format(YYYY_MM_DD),
    antiquity: moment
      .utc(_get(values, 'license.issuedAt', 0))
      .format(YYYY_MM_DD),
    licenseNumber: _get(values, 'license.documentNumber', ''),
    nationality: _get(values, 'license.nationality', ''),
    emissionState: _get(values, 'license.issuingState', '')
      .replace('ESTADO DE MEXICO', 'México')
      .replace('CDMX', 'Ciudad de México'),
    isPermanentLicense,
  };
};
