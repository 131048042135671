export const SAVE_REQUESTED = 'customers/SAVE_REQUESTED';
export const SAVE_SUCCEEDED = 'customers/SAVE_SUCCEEDED';
export const SAVE_FAILED = 'customers/SAVE_FAILED';
export const UPDATE_CUSTOMER_STATUS_REQUESTED =
  'customers/UPDATE_CUSTOMER_STATUS_REQUESTED';
export const UPDATE_CUSTOMER_STATUS_SUCCEEDED =
  'customers/UPDATE_CUSTOMER_STATUS_SUCCEEDED';
export const UPDATE_CUSTOMER_STATUS_FAILED =
  'customers/UPDATE_CUSTOMER_STATUS_FAILED';
export const CLOSE_RESPONSE_DIALOG = 'customers/CLOSE_RESPONSE_DIALOG';
export const SET_RESPONSE_DIALOG = 'customers/SET_RESPONSE_DIALOG';
export const GET_DETAIL_REQUESTED = 'customers/GET_DETAIL_REQUESTED';
export const GET_DETAIL_SUCCEEDED = 'customers/GET_DETAIL_SUCCEEDED';
export const GET_DETAIL_FAILED = 'customers/GET_DETAIL_FAILED';
export const RESET_DETAIL = 'customers/RESET_DETAIL';

export const GET_SUMMARY_INFO_REQUESTED =
  'customers/GET_SUMMARY_INFO_REQUESTED';
export const GET_SUMMARY_INFO_SUCCEEDED =
  'customers/GET_SUMMARY_INFO_SUCCEEDED';
export const GET_SUMMARY_INFO_FAILED = 'customers/GET_SUMMARY_INFO_FAILED';

export const SET_PARAMS_LIST_OF_CUSTOMERS =
  'customers/SET_PARAMS_LIST_OF_CUSTOMERS';
export const RESET_PARAMS_LIST_OF_CUSTOMERS =
  'customers/RESET_PARAMS_LIST_OF_CUSTOMERS';
export const GET_CUSTOMERS_LIST_FILTERS =
  'customers/GET_CUSTOMERS_LIST_FILTERS';
export const GET_CUSTOMERS_LIST_FILTERS_SUCCEED =
  'customers/GET_CUSTOMERS_LIST_FILTERS_SUCCEED';
export const GET_CUSTOMERS_LIST_FILTERS_FAILED =
  'customers/GET_CUSTOMERS_LIST_FILTERS_FAILED';
export const GET_LIST = 'customers/GET_LIST';
export const GET_LIST_SUCCEEDED = 'customers/GET_LIST_SUCCEEDED';
export const GET_LIST_FAILED = 'customers/GET_LIST_FAILED';
export const SET_PAGE_TABLE = 'customers/SET_PAGE_TABLE';
export const MODIFY_FILTERS = 'customers/MODIFY_FILTERS';
export const SET_SEARCH = 'customers/SET_SEARCH';
export const RESET = 'customers/RESET';
export const EXPORT_LIST = 'customers/EXPORT_LIST';
export const DOWNLOAD_ZIP = 'customers/DOWNLOAD_FILES_ZIP';
export const DOWNLOAD_ZIP_FAILED = 'customers/DOWNLOAD_FILES_ZIP_FAILED';
export const BAN = 'customers/BAN';
export const BAN_SUCCEEDED = 'customers/BAN_SUCCEEDED';
export const BAN_FAILED = 'customers/BAN_FAILED';
export const DOWNLOAD_XLSX = 'customers/DOWNLOAD_XLSX_DETAIL';
export const DOWNLOAD_XLSX_FAILED = 'customers/DOWNLOAD_XLSX_DETAIL_FAILED';
export const EDIT_REQUESTED = 'customers/EDIT_REQUESTED';
export const EDIT_SUCCEEDED = 'customers/EDIT_SUCCEEDED';
export const EDIT_FAILED = 'customers/EDIT_FAILED';
export const UPLOAD_DOCUMENT_ADDRESS = 'customers/UPLOAD_DOCUMENT/ADDRESS';
export const UPLOAD_DOCUMENT_LICENSE = 'customers/UPLOAD_DOCUMENT/LICENSE';
export const UPLOAD_DOCUMENT_IDENTITY = 'customers/UPLOAD_DOCUMENT/IDENTITY';
export const UPLOAD_DOCUMENT_SUCCEEDED = 'customers/UPLOAD_DOCUMENT_SUCCEEDED';
export const UPLOAD_DOCUMENT_FAILED = 'customers/UPLOAD_DOCUMENT_FAILED';
export const SEARCH = 'customers/SEARCH';
export const SEARCH_SUCCEEDED = 'customers/SEARCH_SUCCEEDED';
export const SEARCH_FAILED = 'customers/SEARCH_FAILED';

export const SEARCH_EMPLOYEES = 'customers/SEARCH_EMPLOYEES';
export const SEARCH_EMPLOYEES_SUCCEEDED =
  'customers/SEARCH_EMPLOYEES_SUCCEEDED';
export const SEARCH_EMPLOYEES_FAILED = 'customers/SEARCH_EMPLOYEES_FAILED';

export const SEARCH_JURIDICAL_CUSTOMER = 'customers/SEARCH_JURIDICAL_CUSTOMER';
export const SEARCH_JURIDICAL_CUSTOMER_SUCCEEDED =
  'customers/SEARCH_JURIDICAL_CUSTOMER_SUCCEEDED';
export const SEARCH_JURIDICAL_CUSTOMER_FAILED =
  'customers/SEARCH_JURIDICAL_CUSTOMER_FAILED';
export const SEARCH_TENDER_CUSTOMER = 'customers/SEARCH_TENDER_CUSTOMER';
export const SEARCH_TENDER_CUSTOMER_SUCCEEDED =
  'customers/SEARCH_TENDER_CUSTOMER_SUCCEEDED';
export const SEARCH_TENDER_CUSTOMER_FAILED =
  'customers/SEARCH_TENDER_CUSTOMER_FAILED';
export const SEARCH_JURIDICAL_TENDER_CUSTOMER =
  'customers/SEARCH_JURIDICAL_TENDER_CUSTOMER';
export const SEARCH_JURIDICAL_TENDER_CUSTOMER_SUCCEEDED =
  'customers/SEARCH_JURIDICAL_TENDER_CUSTOMER_SUCCEEDED';
export const SEARCH_JURIDICAL_TENDER_CUSTOMER_FAILED =
  'customers/SEARCH_JURIDICAL_TENDER_CUSTOMER_FAILED';

export const VALIDATE_CURP = 'customers/VALIDATE_CURP';
export const VALIDATE_CURP_SUCCEEDED = 'customers/VALIDATE_CURP_SUCCEEDED';
export const VALIDATE_CURP_FAILED = 'customers/VALIDATE_CURP_FAILED';
export const CLEAN_VALIDATE_CURP = 'customers/CLEAN_VALIDATE_CURP';

export const VALIDATE_CURP_DRIVER = 'customers/VALIDATE_CURP_DRIVER';
export const VALIDATE_CURP_DRIVER_SUCCEEDED =
  'customers/VALIDATE_CURP_DRIVER_SUCCEEDED';
export const VALIDATE_CURP_DRIVER_FAILED =
  'customers/VALIDATE_CURP_DRIVER_FAILED';
export const CLEAN_VALIDATE_CURP_DRIVER =
  'customers/CLEAN_VALIDATE_CURP_DRIVER';

export const VALIDATE_ENDORSEMENT_CURP = 'customers/VALIDATE_ENDORSEMENT_CURP';
export const VALIDATE_ENDORSEMENT_CURP_SUCCEEDED =
  'customers/VALIDATE_ENDORSEMENT_CURP_SUCCEEDED';
export const VALIDATE_ENDORSEMENT_CURP_FAILED =
  'customers/VALIDATE_ENDORSEMENT_CURP_FAILED';
export const CLEAN_VALIDATE_ENDORSEMENT_CURP =
  'customers/CLEAN_VALIDATE_ENDORSEMENT_CURP';

export const ADD_BILLING_INFO = 'customers/ADD_BILLING_INFO';
export const ADD_BILLING_INFO_SUCCEEDED =
  'customers/ADD_BILLING_INFO_SUCCEEDED';
export const ADD_BILLING_INFO_FAILED = 'customers/ADD_BILLING_INFO_FAILED';

export const DELETE_BILLING_INFO = 'customers/DELETE_BILLING_INFO';
export const DELETE_BILLING_INFO_SUCCEEDED =
  'customers/DELETE_BILLING_INFO_SUCCEEDED';
export const DELETE_BILLING_INFO_FAILED =
  'customers/DELETE_BILLING_INFO_FAILED';

export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const SET_CUSTOMER_PERSON_TYPE = 'SET_CUSTOMER_PERSON_TYPE';
export const SET_TENDER_TYPE = 'SET_TENDER_TYPE';

export const SAVE_CUSTOMER_FOREGINER = 'customers/SAVE_CUSTOMER_FOREGINER';
export const SAVE_CUSTOMER_FOREGINER_SUCCEEDED =
  'customers/SAVE_CUSTOMER_FOREGINER_SUCCEEDED';
export const SAVE_CUSTOMER_FOREGINER_FAILED =
  'customers/SAVE_CUSTOMER_FOREGINER_FAILED';

export const SAVE_CUSTOMER_FOREGINER_BASE =
  'customers/SAVE_CUSTOMER_FOREGINER_BASE';
export const SAVE_CUSTOMER_FOREGINER_BASE_SUCCEEDED =
  'customers/SAVE_CUSTOMER_FOREGINER_BASE_SUCCEEDED';
export const SAVE_CUSTOMER_FOREGINER_BASE_FAILED =
  'customers/SAVE_CUSTOMER_FOREGINER_BASE_FAILED';

export const SAVE_CUSTOMER_BASE = 'customers/SAVE_CUSTOMER_BASE';
export const SAVE_CUSTOMER_BASE_SUCCEEDED =
  'customers/SAVE_CUSTOMER_BASE_SUCCEEDED';
export const SAVE_CUSTOMER_BASE_FAILED = 'customers/SAVE_CUSTOMER_BASE_FAILED';

export const SAVE_AVAL_CUSTOMER = 'customers/SAVE_AVAL_CUSTOMER';
export const SAVE_AVAL_CUSTOMER_SUCCEEDED =
  'customers/SAVE_AVAL_CUSTOMER_SUCCEEDED';
export const SAVE_AVAL_CUSTOMER_FAILED = 'customers/SAVE_AVAL_CUSTOMER_FAILED';
export const RESET_AVAL_CUSTOMER = 'customers/RESET_AVAL_CUSTOMER';
export const SET_AVAL_CUSTOMER = 'customers/SET_AVAL_CUSTOMER';

export const SET_PREVENT_CHANGE = 'customers/SET_PREVENT_CHANGE';

export const SET_FORM_VALUES = 'customers/SET_FORM_VALUES';
export const CLEAN_FORM_VALUES = 'customers/CLEAN_FORM_VALUES';

export const SET_AVAL_PREVENT_CHANGE = 'customers/AVAL_PREVENT_CHANGE';

export const SET_SELECTION_OF_AVAL_CUSTOMER_BY_DETAIL =
  'customers/SET_SELECT_AVAL_CUSTOMER_BY_DETAIL';

export const SET_AVAL_CUSTOMER_BY_DETAIL =
  'customers/SET_AVAL_CUSTOMER_BY_DETAIL';

export const HYDRATE_CUSTOMER_EDITION = 'customers/HYDRATE_CUSTOMER_EDITION';
export const HYDRATE_CUSTOMER_EDITION_SUCCEEDED =
  'customers/HYDRATE_CUSTOMER_EDITION_SUCCEEDED';
export const HYDRATE_CUSTOMER_EDITION_FAILED =
  'customers/HYDRATE_CUSTOMER_EDITION_FAILED';

export const GET_STATEMENT = 'customers/GET_STATEMENT';
export const GET_STATEMENT_SUCCEEDED = 'customers/GET_STATEMENT_SUCCEEDED';
export const GET_STATEMENT_FAILED = 'customers/GET_STATEMENT_FAILED';

export const GET_LINK_TO_VALIDATE_IDENTITY =
  'customers/GET_LINK_TO_VALIDATE_IDENTITY';
export const GET_LINK_TO_VALIDATE_IDENTITY_SUCCEEDED =
  'customers/GET_LINK_TO_VALIDATE_IDENTITY_SUCCEEDED';
export const GET_LINK_TO_VALIDATE_IDENTITY_FAILED =
  'customers/GET_LINK_TO_VALIDATE_IDENTITY_FAILED';
export const CLOSE_RESPONSE_GET_LINK_TO_VALIDATE_IDENTITY =
  'customers/CLOSE_RESPONSE_GET_LINK_TO_VALIDATE_IDENTITY';
export const CLOSE_RESPONSE_VALIDATE_IDENTITY =
  'customers/CLOSE_RESPONSE_VALIDATE_IDENTITY';

export const VALIDATE_IDENTITY = 'customers/VALIDATE_IDENTITY';
export const VALIDATE_IDENTITY_SUCCEEDED =
  'customers/VALIDATE_IDENTITY_SUCCEEDED';
export const VALIDATE_IDENTITY_FAILED = 'customers/VALIDATE_IDENTITY_FAILED';

export const GET_LINK_TO_VALIDATE_LICENSE =
  'customers/GET_LINK_TO_VALIDATE_LICENSE';
export const GET_LINK_TO_VALIDATE_LICENSE_SUCCEEDED =
  'customers/GET_LINK_TO_VALIDATE_LICENSE_SUCCEEDED';
export const GET_LINK_TO_VALIDATE_LICENSE_FAILED =
  'customers/GET_LINK_TO_VALIDATE_LICENSE_FAILED';
export const CLOSE_RESPONSE_GET_LINK_TO_VALIDATE_LICENSE =
  'customers/CLOSE_RESPONSE_GET_LINK_TO_VALIDATE_LICENSE';
export const CLOSE_RESPONSE_VALIDATE_LICENSE =
  'customers/CLOSE_RESPONSE_VALIDATE_LICENSE';

export const VALIDATE_LICENSE = 'customers/VALIDATE_LICENSE';
export const VALIDATE_LICENSE_SUCCEEDED =
  'customers/VALIDATE_LICENSE_SUCCEEDED';
export const VALIDATE_LICENSE_FAILED = 'customers/VALIDATE_LICENSE_FAILED';
